.header {
  position: sticky;
  background-color: white;
  min-width: 100%;
  top: 0;
  left: 0;
  z-index: 90;
}

.Header__Wrapper {
  padding-top: 1.2rem;
  padding-right: 3.5rem;
  padding-bottom: 1.2rem;
  padding-left: 3.5rem;
  display: flex;
  flex-wrap: wrap;
  max-height: 90px;
  max-width: 100%;
  align-items: center;
  z-index: 99;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 2rem;
  flex: 1 0 0;
}

.logo-image {
  order: -1;
  margin-right: 2.6rem;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: 0.9rem;
}

.header-right > :nth-child(1) {
  color: #939393;
}

.header-right > :nth-child(2) {
  color: #939393;
}

.Buttom span {
  padding: 16px 45px 16px 45px;
  background-color: rgb(255, 69, 69);
  font-size: 1.2rem;
  color: rgb(255, 255, 255);
  text-align: center;
}

.ham {
  display: none;
}

@media (max-width: 1138px) {
  .ham {
    display: block;
    cursor: pointer;
    align-items: center;
  }
  .ham .line {
    width: 30px;
    height: 3px;
    background: rgb(113, 113, 113);
    margin: 5px 0;
  }

  .header-left {
      position: fixed;
      top: 47vh;
      min-width: 10rem;
      height: 100%;
      right: 0;
      background: rgb(83, 23, 23);
      z-index: 90;
      flex-direction: column;
      padding: 3rem;
      justify-content: flex-start;
      transition: 0.5s;
      transform: translateX(100%);
  }


  .header-left a{
    color: white;
    text-align: left;
  }

  .header-right {
    display: none;
  }

  .Header__Wrapper {
    justify-content: space-between;
    max-height: 50px;
    max-width: 100%;
    align-items: center;
    padding: 10px;
  }

  .logo-image img{
    width: 90px;
  }

  .nav_active{
    transform: translateX(0);
  }


}


/* @media (max-width : 429px) {
  .header-left {
    position: fixed;
    top: 22vh;
    max-width: 15rem;
    height: 100%;
    right: 0;
    background: rgb(83, 23, 23);
    z-index: 90;
    flex-direction: column;
    padding: 3rem;
    justify-content: flex-start;
    transition: 0.5s;
    transform: translateX(100%);
    
}

.nav_active{
  transform: translateX(0);
}




} */
